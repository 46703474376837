import React, {FC, Fragment, FormEvent, useEffect, useCallback, useState} from 'react';
import i18n from 'i18next';
import {onVerifySubmit, onVerifyCodeSubmit, BoolCallback, toBoolean} from './form';
import {getAuthStatus, JsonApiDocumentError, JsonApiResponse, isJsonApiData, logError, getFirstError} from './client';
import {getConfig, ThemeClasses} from './config';

type Props = {
	onComplete: BoolCallback;
}

type ClassNames = {
	wrapper: string;
	label: string;
	input: string;
	button: string
}

const CLASSES: ThemeClasses<ClassNames> = {
	bootstrap: {
		wrapper: 'mb-3',
		label: 'form-label',
		input: 'form-control',
		button: 'btn btn-primary'
	}
};

type VerifyFormProps = {
	errorDocument: JsonApiDocumentError;
	onComplete: BoolCallback;
}

const StatusReport: FC<{response: JsonApiResponse}> = ({response}) => {
	let report = i18n.t('statusLoading');
	if (isJsonApiData(response)) {
		report = i18n.t('statusLoaded');
	} else if (response.errors[0] && response.errors[0].title) {
		report = response.errors[0].title;

	}

	return <strong>{report}</strong>;
};

const VerifyForm: FC<VerifyFormProps> = ({errorDocument, onComplete}) => {
	const mainError = getFirstError(errorDocument);
	const [step, setStep] = useState<number>(0);
	const handleSubmit = useCallback(
		(event: FormEvent<HTMLFormElement>) => {
			if (step === 0) {
				onVerifySubmit(event)
					.then((result) => {
						if (result) {
							setStep(1);
						}

						return;
					})
					.catch(logError);
			} else if (step === 1) {
				onVerifyCodeSubmit(event)
					.then((result) => {
						if (result) {
							setStep(0);
							onComplete(true);
						}

						return;
					})
					.catch(logError);
			}
		},
		[step, setStep]
	);

	const c = CLASSES[getConfig().theme];
	return <form onSubmit={handleSubmit}>
		<input
			type="hidden"
			name="strategy"
			value={mainError.code}
		/>
		{
			step === 1 ?
				<div className={c.wrapper}>
					<label
						className={c.label}
						htmlFor="verify-code">
						{i18n.t('verificationCode')}
					</label>
					<input
						required
						className={c.input}
						id="verify-code"
						type="number"
						name="code"
						placeholder="000000"
					/>
				</div> :
				null
		}
		<button className={c.button}>
			{i18n.t('verifyButton', {strategy: i18n.t(`${mainError.code}Strategy`)})}
		</button>
	</form>;
};

const StatusForm: FC<Props> = ({onComplete}) => {
	const [response, setResponse] = useState<undefined | JsonApiResponse>(undefined);

	useEffect(() => {
		getAuthStatus()
			.then((res) => {
				onComplete(toBoolean(res));
				return setResponse(res);
			})
			.catch(logError);
	}, []);

	const handleVerified = useCallback(
		() => {
			getAuthStatus()
				.then((res) => {
					onComplete(toBoolean(res));
					return setResponse(res);
				})
				.catch(logError);
		},
		[]
	);

	return !response ?
		null :
		<Fragment>
			<StatusReport response={response} />
			{
				!isJsonApiData(response) ?
					<VerifyForm
						onComplete={handleVerified}
						errorDocument={response} /> :
					null
			}
		</Fragment>;
};

export default StatusForm;
