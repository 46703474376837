import React, {FC, ReactNode} from 'react';
import {isLoggedIn, getProfileInfo, UserProfile} from './auth';

type PorterUserContext = {
	profile: UserProfile | undefined;
	isLoggedIn: boolean;
}

const UserContext = React.createContext<PorterUserContext>({
	profile: undefined,
	isLoggedIn: false
});

type Props = {
	children: ReactNode;
}

export const UserProvider: FC<Props> = ({children}) =>
	<UserContext.Provider value={{
		profile: getProfileInfo(),
		isLoggedIn: isLoggedIn()
	}}>
		{children}
	</UserContext.Provider>;

export default UserContext;
