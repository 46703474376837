import React, {FC, Fragment, FormEvent, useCallback, useState} from 'react';
import i18n from 'i18next';
import {onLogInSubmit, onLogInCodeSubmit, BoolCallback} from './form';
import {isLoggedIn} from './auth';
import {Strategy, logError, isJsonApiData, isSingleJsonApiResource} from './client';
import {getConfig, ThemeClasses} from './config';

type Props = {
	onComplete: BoolCallback;
}

type ClassNames = {
	wrapper: string;
	label: string;
	input: string;
	button: string
}

const CLASSES: ThemeClasses<ClassNames> = {
	bootstrap: {
		wrapper: 'mb-3',
		label: 'form-label',
		input: 'form-control',
		button: 'btn btn-primary'
	}
};

const LogInForm: FC<Props> = ({onComplete}) => {
	if (isLoggedIn()) {
		onComplete(true);
	}

	const [step, setStep] = useState<number>(0);
	const [strategy, setStrategy] = useState<Strategy | undefined>(undefined);
	const [qrCode, setQrCode] = useState<undefined | string>(undefined);
	const handleSubmit = useCallback(
		(event: FormEvent<HTMLFormElement>) => {
			if (step === 0) {
				onLogInSubmit(event)
					.then((response) => {
						if (isSingleJsonApiResource(response)) {
							const strategyId = response.data.id as Strategy;
							setStrategy(strategyId);
							if (strategyId === 'totp-new') {
								setQrCode(response.data.attributes?.qr);
							}

							setStep(1);
						}

						return;
					})
					.catch(logError);
			} else if (step === 1) {
				onLogInCodeSubmit(event)
					.then((response) => {
						if (isJsonApiData(response)) {
							setStep(0);
							onComplete(true);
						}

						return;
					})
					.catch(logError);
			}
		},
		[step, setStep]
	);

	const c = CLASSES[getConfig().theme];
	return <form onSubmit={handleSubmit}>
		<div className={c.wrapper}>
			<label
				className={c.label}
				htmlFor="log-in-email">
				{i18n.t('form.email.label')}
			</label>
			<input
				required
				className={c.input}
				id="log-in-email"
				type="email"
				name="email"
				placeholder={i18n.t('form.email.placeholder')} />
		</div>
		<div className={c.wrapper}>
			<label
				className={c.label}
				htmlFor="log-in-phone-number">
				{i18n.t('form.phone.label')}
			</label>
			<input
				required
				className={c.input}
				id="log-in-phone-number"
				type="tel"
				name="phone"
				placeholder={i18n.t('form.phone.placeholder')}
			/>
		</div>
		{
			step === 1 ?
				<Fragment>
					{
						strategy === 'totp-new' ?
							<div className={c.wrapper}>
								<p>{i18n.t('mfaSetup')}</p>
								<img src={qrCode} />
							</div> :
							null
					}
					<div className={c.wrapper}>
						<label
							className={c.label}
							htmlFor="verify-code">
							{i18n.t('verificationCode')}
						</label>
						<input
							required
							className={c.input}
							id="verify-code"
							type="number"
							name="code"
							placeholder="000000"
						/>
					</div>
				</Fragment> :
				null
		}
		<button className={c.button}>
			{
				step === 0 ?
					i18n.t('loginButton') :
					i18n.t('verifyButton', {strategy: i18n.t(`${strategy}Strategy`)})
			}
		</button>
	</form>;
};

export default LogInForm;
