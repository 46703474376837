import i18n, {Resource} from 'i18next';

type Theme = 'bootstrap';

export type ThemeClasses<T> = {
	[key in Theme]: T;
};

export type PorterConfig = {
	isDebug: boolean;
	applicationName: string;
	apiHost: string;
	theme: Theme;
	logInUrl: string;
	signUpUrl: string;
	dashboardUrl: string;
	i18n: Resource;
}

let CONFIG = {
	isDebug: true,
	applicationName: '',
	apiHost: 'https://api.porter.is',
	theme: 'bootstrap' as Theme,
	logInUrl: '/log-in/',
	signUpUrl: '/sign-up/',
	dashboardUrl: '/dashboard/',
	i18n: {
		en: {
			translation: {
				statusLoading: 'Loading Status ...',
				statusLoaded: 'Looking Good',
				login: 'Log In',
				logout: 'Logout',
				dashboard: 'Dashboard',
				greeting: 'Hi {{name}}!',
				verificationCode: 'Verification Code',
				signUpButton: 'Sign Up',
				loginButton: 'Log In',
				verifyButton: 'Verify {{strategy}} Code',
				mfaSetup: 'Please scan this barcode to add your device',
				emailStrategy: 'Email',
				smsStrategy: 'SMS',
				phoneStrategy: 'Phone',
				mfaStrategy: 'One-Time-Generator',
				totpStrategy: 'One-Time-Generator',
				'totp-newStrategy': 'New One-Time-Generator Device',
				setupPlan: 'Please select a service plan.',
				changePlan: 'Change to this plan',
				currentPlan: 'Current Plan',
				form: {
					email: {
						label: 'Email',
						placeholder: 'you@yourcompany.com'
					},
					phone: {
						label: 'Phone Number',
						placeholder: '+1 123-4567-8901'
					}
				}
			}
		}
	}
};

export const setConfig = (conf: {
	isDebug?: boolean;
	applicationName?: string;
}): void => {
	CONFIG = {
		...CONFIG,
		...conf
	};

	i18n.init({
		lng: 'en',
		resources: CONFIG.i18n
	});
};

export const getConfig = (): PorterConfig => CONFIG;
