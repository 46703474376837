import React, {FC, useCallback} from 'react';
import i18n from 'i18next';
import {JsonApiResource, ServicePlan, setCurrentPlan, logError} from './client';
import {getConfig, ThemeClasses} from './config';

type Props = {
	onChange: () => void;
	servicePlans: JsonApiResource<ServicePlan>[];
	currentPlanId: string;
}

type ItemProps = {
	onChange: () => void;
	servicePlan: JsonApiResource<ServicePlan>;
	active: boolean;
}

type ClassNames = {
	wrapper: string;
	button: string;
	buttonActive: string;
	badge: string;
}

const CLASSES: ThemeClasses<ClassNames> = {
	bootstrap: {
		wrapper: 'list-group list-group-flush',
		button: 'list-group-item list-group-item-action',
		buttonActive: 'list-group-item list-group-item-action active',
		badge: 'badge bg-light text-dark'
	}
};

const getPrice = (price: number, currency: string) =>
	new Intl.NumberFormat('en-US', {style: 'currency', currency}).format(price / 100);

const PlanItem: FC<ItemProps> = ({servicePlan, active, onChange}) => {
	const handleClick = useCallback(() => {
		setCurrentPlan(servicePlan.id)
			.then(() => onChange())
			.catch(logError);

	}, [servicePlan]);

	const c = CLASSES[getConfig().theme];
	const desc = `${servicePlan.attributes?.planTitle}`;
	return <button
		className={active ? c.buttonActive : c.button}
		type="button"
		disabled={active}
		onClick={handleClick}>
		{desc}{' '}
		{getPrice(servicePlan.attributes?.price || 0, servicePlan.attributes?.currency || 'USD')}
		<br />
		<span className={c.badge}>
			{
				active ?
					i18n.t('currentPlan') :
					i18n.t('changePlan')
			}</span>

	</button>;
};

const ServicePlanList: FC<Props> = ({servicePlans, currentPlanId, onChange}) =>
	<div className={CLASSES[getConfig().theme].wrapper}>
		{
			servicePlans.map((servicePlan) =>
				<PlanItem
					key={servicePlan.id}
					onChange={onChange}
					active={servicePlan.id === currentPlanId}
					servicePlan={servicePlan}
				/>
			)
		}
	</div>;

export default ServicePlanList;
