import React, {FC, Fragment, useContext} from 'react';
import i18n from 'i18next';
import classnames from 'classnames';
import UserContext from './UserContext';
import {logout} from './client';
import {getConfig, ThemeClasses} from './config';

type Props = {
	className?: string;
}

type ClassNames = {
	wrapper: string;
	subWrapper: string;
	userWrapper: string;
	button: string;
}

const CLASSES: ThemeClasses<ClassNames> = {
	bootstrap: {
		wrapper: 'navbar-nav',
		subWrapper: 'nav-item dropdown',
		userWrapper: 'nav-link dropdown-toggle',
		button: 'btn-outline-sm'
	}
};

// eslint-disable-next-line max-len
const PATH = 'M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z';

const UserWidget: FC<Props> = ({className}) => {
	const context = useContext(UserContext);
	const config = getConfig();
	const c = CLASSES[config.theme];

	if (!context.isLoggedIn) {
		return <Fragment>
			<a className={classnames(c.button, className)}
				href={config.signUpUrl}>
				{i18n.t('signUpButton')}
			</a>
			<a className={classnames(c.button, className)}
				href={config.logInUrl}>
				{i18n.t('loginButton')}
			</a>
		</Fragment>;
	}

	return <ul
		className={classnames(c.wrapper, className)}
		title={i18n.t('greeting', {name: context.profile?.user.firstName || context.profile?.sub})}
	>
		<li className={c.subWrapper}>
			<a
				className={c.userWrapper}
				href="#"
				id="navbarDropdownMenuLink"
				role="button"
				data-toggle="dropdown"
				aria-haspopup="true"
				aria-expanded="false">
				<svg xmlns="http://www.w3.org/2000/svg"
					width="16"
					height="16"
					fill="currentColor"
					className="bi bi-person"
					viewBox="0 0 16 16">
					<path d={PATH} />
				</svg>
			</a>
			<div className="dropdown-menu"
				aria-labelledby="navbarDropdownMenuLink">
				<a className="dropdown-item"
					href={config.dashboardUrl}>{i18n.t('dashboard')}</a>
				<a className="dropdown-item"
					onClick={logout}
					href="#">{i18n.t('logout')}</a>
			</div>
		</li>
	</ul>;
};

export default UserWidget;
