import React, {FC, Fragment, useEffect, useState, useCallback} from 'react';
import i18n from 'i18next';
import {BoolCallback, toBoolean} from './form';
import {
	getServicePlans,
	getCurrentPlan,
	logError,
	JsonApiResponse,
	JsonApiResource,
	ServicePlan,
	isJsonApiData,
	isSingleJsonApiResource
} from './client';
import ServicePlanList from './ServicePlanList';

type Props = {
	onComplete: BoolCallback;
}

const THERE_IS_NO_PLAN = '00000000-0000-0000-0000-000000000000';

const NoPlanCta: FC<{id: string}> = ({id}) =>
	id === THERE_IS_NO_PLAN ?
		<p>
			<strong>
				{i18n.t('setupPlan')}
			</strong>
		</p> :
		null;

const ServiceForm: FC<Props> = ({onComplete}) => {
	const [response, setResponse] = useState<undefined | JsonApiResponse<JsonApiResource<ServicePlan>[]>>(undefined);
	const [currentPlan, setCurrent] = useState<undefined | JsonApiResponse<unknown>>(undefined);

	useEffect(() => {
		getServicePlans()
			.then((res) => {
				onComplete(toBoolean(res));
				return setResponse(res);
			})
			.catch(logError);
	}, []);

	useEffect(() => {
		getCurrentPlan()
			.then((res) => {
				if (isJsonApiData(res)) {
					setCurrent(res);
				}

				return;
			})
			.catch(logError);
	}, []);

	const handleChange = useCallback(
		() => {
			getCurrentPlan()
				.then((res) => {
					if (isJsonApiData(res)) {
						setCurrent(res);
					}

					return;
				})
				.catch(logError);
		},
		[]
	);

	const currentPlanId = currentPlan && isSingleJsonApiResource(currentPlan) ?
		currentPlan.data.id :
		'';
	return <Fragment>
		<NoPlanCta id={currentPlanId} />
		{
			response && isJsonApiData(response) && Array.isArray(response.data) ?
				<ServicePlanList
					onChange={handleChange}
					currentPlanId={currentPlanId}
					servicePlans={response.data as unknown as JsonApiResource<ServicePlan>[]}
				/> :
				null
		}
	</Fragment>;
};

export default ServiceForm;
