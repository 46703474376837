import Cookie, {CookieAttributes} from 'js-cookie';
import decode from 'jwt-decode';
import {getConfig} from './config';

const COOKIE_AUTH_ID = 'porter_id';

export type UserProfile = {
	aud: string;
	sub: string;
	jti: string;
	exp: number;
	iat: number;
	user: {
		firstName: string;
		lastName: string;
		service: string;
		exp: number;
	};
}

export const COOKIE_SETTINGS: CookieAttributes = {
	expires: 7,
	domain: typeof location !== 'undefined' ? location.hostname : '',
	secure: true,
	sameSite: 'strict'
};

export const getToken = (): string | undefined => Cookie.get(COOKIE_AUTH_ID);

export const setToken = (idToken: string): void => {
	Cookie.set(
		COOKIE_AUTH_ID,
		idToken,
		COOKIE_SETTINGS
	);
};

export const removeToken = (): void => Cookie.remove(COOKIE_AUTH_ID, COOKIE_SETTINGS);

export const getProfileInfo = (token = getToken()): UserProfile | undefined => {
	if (token) {
		return decode(token);
	}

	return undefined;
};

export const getTokenExpirationDate = (decoded: UserProfile): null | Date => {
	try {
		if (!decoded.exp) {
			return null;
		}

		const date = new Date(0);
		date.setUTCSeconds(decoded.exp);
		return date;
	} catch (e) {
		return new Date(0);
	}
};

export const isTokenValid = (token: string): boolean => {
	const claims = getProfileInfo(token);
	if (!claims) {
		return false;
	}

	const config = getConfig();
	if (config.applicationName !== claims.aud) {
		return false;
	}

	const date = getTokenExpirationDate(claims);
	if (date === null) {
		return true;
	}

	return date.valueOf() > new Date().valueOf();
};

export const isLoggedIn = (): boolean => {
	const idToken = getToken();
	return !!idToken &&
		isTokenValid(idToken);
};
