/* eslint-disable promise/catch-or-return */
import {FormEvent} from 'react';
import {
	JsonApiDocumentError,
	signUp,
	logIn,
	logInWithCode,
	verify,
	verifyCode,
	JsonApiResponse,
	AuthStrategy
} from './client';

// eslint-disable-next-line no-unused-vars
export type BoolCallback = (result: boolean) => void;

// eslint-disable-next-line no-unused-vars
type FormHandler = (event: FormEvent<HTMLFormElement>) => Promise<JsonApiResponse>;

export const toBoolean = <T>(response: JsonApiResponse<T>): boolean =>
	'data' in response;

export const toBoolCallback = (handler: FormHandler, callback: BoolCallback) =>
	(event: FormEvent<HTMLFormElement>): void => {
		handler(event)
			.then(toBoolean)
			.then(callback);
	};

export const INVALID_FORM: JsonApiDocumentError = {
	errors: [{id: 'invalid-form'}]
};

export const onSignUpSubmit = (event: FormEvent<HTMLFormElement>): Promise<JsonApiResponse> => {
	event.preventDefault();
	const form = event.target as HTMLFormElement;
	if (form.checkValidity()) {
		const data = new FormData(form);
		const phone = data.get('phone') as string;
		return signUp({
			email: data.get('email') as string,
			phoneNumber: phone.replace(/\D+/g, '')
		});
	}

	return Promise.resolve(INVALID_FORM);
};

export const onLogInSubmit = (event: FormEvent<HTMLFormElement>): Promise<JsonApiResponse<AuthStrategy>> => {
	event.preventDefault();
	const form = event.target as HTMLFormElement;
	if (form.checkValidity()) {
		const data = new FormData(form);
		const phone = data.get('phone') as string;
		return logIn({
			email: data.get('email') as string,
			phoneNumber: phone.replace(/\D+/g, '')
		});
	}

	return Promise.resolve(INVALID_FORM);
};

export const onLogInCodeSubmit = (event: FormEvent<HTMLFormElement>): Promise<JsonApiResponse> => {
	event.preventDefault();
	const form = event.target as HTMLFormElement;
	if (form.checkValidity()) {
		const data = new FormData(form);
		const phone = data.get('phone') as string;
		const code = data.get('code') as string;
		return logInWithCode(
			{
				email: data.get('email') as string,
				phoneNumber: phone.replace(/\D+/g, '')
			},
			code
		);
	}

	return Promise.resolve(INVALID_FORM);
};

export const onVerifySubmit = (event: FormEvent<HTMLFormElement>): Promise<JsonApiResponse> => {
	event.preventDefault();
	const form = event.target as HTMLFormElement;
	if (form.checkValidity()) {
		const data = new FormData(form);
		const strategy = data.get('strategy');
		if (strategy && strategy === 'email' || strategy === 'phone') {
			return verify(strategy);
		}
	}

	return Promise.resolve(INVALID_FORM);
};

export const onVerifyCodeSubmit = (event: FormEvent<HTMLFormElement>): Promise<JsonApiResponse> => {
	event.preventDefault();
	const form = event.target as HTMLFormElement;
	if (form.checkValidity()) {
		const data = new FormData(form);
		const strategy = data.get('strategy');
		const code = data.get('code') as string;
		if (strategy && strategy === 'email' || strategy === 'phone') {
			return verifyCode(strategy, code);
		}
	}

	return Promise.resolve(INVALID_FORM);
};
