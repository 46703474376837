import {getToken, removeToken, setToken} from './auth';
import {getConfig} from './config';

const API_HOST = getConfig().apiHost;

export type Strategy = 'sms' | 'email' | 'totp' | 'totp-new';

const DEFAULT_HEADERS = {
	'Content-Type': 'application/json'
};

type AuthRequest = {
	email: string;
	phoneNumber: string;
}

type AuthHeaders = {
	'Content-Type': string;
	Authorization: string;
	'X-App-Name': string;
}

export type JsonApiError = {
	id?: string;
	title?: string;
	code?: string;
}

export type JsonApiResource<Attributes = undefined> = {
	type: string;
	id: string;
	attributes?: Attributes;
}

export type JsonApiDocumentData<T> = {
	data: JsonApiResource<T> | JsonApiResource<T>[];
}

export type JsonApiDocumentError = {
	errors: JsonApiError[];
}

export type JsonApiResponse<T = undefined> = JsonApiDocumentError | JsonApiDocumentData<T>;

export type ServicePlan = {
	serviceId: string;
	serviceName: string;
	serviceTitle: string;
	features?: string;
	planName: string;
	planTitle: string;
	model: 'free' | 'fixed' | 'metered' | 'tiered' | 'custom';
	interval: 'day' | 'month' | 'year';
	intervalCount: number;
	price: number;
	currency: string;
}

export type AuthStrategy = {
	qr: string;
}

export const isJsonApiData = <T = undefined>(response: JsonApiResponse<T>): response is JsonApiDocumentData<T> =>
	// eslint-disable-next-line no-extra-parens
	(response as JsonApiDocumentData<T>).data !== undefined;

export const isMultipleJsonApiResource = <T>(response: JsonApiResponse<T>): boolean =>
	isJsonApiData(response) && Array.isArray(response.data);

export const isSingleJsonApiResource = <T>(response: JsonApiResponse<T>
): response is {data: JsonApiResource<T>} => isJsonApiData(response) && !Array.isArray(response.data);

export const getFirstError = (response: JsonApiDocumentError): JsonApiError =>
	response.errors && response.errors[0] ? response.errors[0] : {};

export const logError = (e: Error | unknown): void => {
	if (getConfig().isDebug) {
		// eslint-disable-next-line no-console
		console.error(e);
	}
};

export const catchWrapError = (error: Error): JsonApiDocumentError => {
	logError(error);
	return {
		errors: [{
			title: error.message
		}]
	};
};

export const getAuthHeaders = (): AuthHeaders => {
	const token = getToken();
	return {
		...DEFAULT_HEADERS,
		Authorization: token || '',
		'X-App-Name': getConfig().applicationName
	};
};

const getAuthRequest = (params: AuthRequest): string =>
	JSON.stringify({
		...params,
		applicationName: getConfig().applicationName
	});

export const fetchPostGuest = async <T = undefined>(url: string, body: string): Promise<JsonApiResponse<T>> => {
	try {
		const response = await fetch(url, {
			method: 'POST',
			headers: DEFAULT_HEADERS,
			body
		});

		const json = await response.json();
		return response.ok ?
			json as JsonApiDocumentData<T> :
			json as JsonApiDocumentError;

	} catch (error) {
		logError(error);
		return error;
	}
};

export const fetchGetGuest = async <T = undefined>(url: string): Promise<JsonApiResponse<T>> => {
	try {
		const response = await fetch(url, {
			method: 'GET',
			headers: {
				...DEFAULT_HEADERS,
				'X-App-Name': getConfig().applicationName
			}
		});

		const json = await response.json();
		return response.ok ?
			json as JsonApiDocumentData<T> :
			json as JsonApiDocumentError;

	} catch (error) {
		logError(error);
		return error;
	}
};

export const fetchPostAuth = async <T = undefined>(url: string, body: string): Promise<JsonApiResponse<T>> => {
	try {
		const response = await fetch(url, {
			method: 'POST',
			headers: getAuthHeaders(),
			body
		});

		const json = await response.json();
		return response.ok ?
			json as JsonApiDocumentData<T> :
			json as JsonApiDocumentError;

	} catch (error) {
		logError(error);
		return error;
	}
};

export const fetchPutAuth = async <T = undefined>(url: string, body: string): Promise<JsonApiResponse<T>> => {
	try {
		const response = await fetch(url, {
			method: 'PUT',
			headers: getAuthHeaders(),
			body
		});

		const json = await response.json();
		return response.ok ?
			json as JsonApiDocumentData<T> :
			json as JsonApiDocumentError;

	} catch (error) {
		logError(error);
		return error;
	}
};

export const fetchGetAuth = async <T = undefined>(url: string): Promise<JsonApiResponse<T>> => {
	try {
		const response = await fetch(url, {
			method: 'GET',
			headers: getAuthHeaders()
		});

		const json = await response.json();
		return response.ok ?
			json as JsonApiDocumentData<T> :
			json as JsonApiDocumentError;

	} catch (error) {
		logError(error);
		return error;
	}
};

export const signUp = async (params: AuthRequest): Promise<JsonApiResponse> =>
	fetchPostGuest(`${API_HOST}/signup`, getAuthRequest(params))
		.then((response) => {
			if (isSingleJsonApiResource(response)) {
				setToken(response.data.id);
			}

			return response;
		})
		.catch(catchWrapError);

export const logIn = (params: AuthRequest): Promise<JsonApiResponse<AuthStrategy>> =>
	fetchPostGuest<AuthStrategy>(`${API_HOST}/login`, getAuthRequest(params))
		.catch(catchWrapError);

export const logInWithCode = (params: AuthRequest, code: string): Promise<JsonApiResponse> =>
	fetchPostGuest(`${API_HOST}/login/${code}`, getAuthRequest(params))
		.then((response) => {
			if (isSingleJsonApiResource(response)) {
				setToken(response.data.id);
			}

			return response;
		})
		.catch(catchWrapError);

export const logout = (): Promise<JsonApiResponse> =>
	fetchGetAuth(`${API_HOST}/logout`)
		.then((response) => {
			removeToken();
			window.location.href = '';

			return response;
		})
		.catch(catchWrapError);

export const getAuthStatus = (): Promise<JsonApiResponse> =>
	fetchGetAuth(`${API_HOST}/w`)
		.catch(catchWrapError);

export const verify = (strategy: 'email' | 'phone'): Promise<JsonApiResponse> =>
	fetchGetAuth(`${API_HOST}/account/verify/${strategy}`)
		.catch(catchWrapError);

export const verifyCode = (strategy: 'email' | 'phone', code: string): Promise<JsonApiResponse> =>
	fetchGetAuth(`${API_HOST}/account/verify/${strategy}/${code}`)
		.catch(catchWrapError);

export const getServicePlans = (): Promise<JsonApiResponse<JsonApiResource<ServicePlan>[]>> =>
	fetchGetGuest<JsonApiResource<ServicePlan>[]>(`${API_HOST}/services/plans`)
		.catch(catchWrapError);

export const getCurrentPlan = <T>(): Promise<JsonApiResponse<T>> =>
	fetchGetAuth<T>(`${API_HOST}/account/plan`)
		.catch(catchWrapError);

export const setCurrentPlan = <T>(id: string): Promise<JsonApiResponse<T>> =>
	fetchPostAuth<T>(`${API_HOST}/account/plan/${id}`, '')
		.catch(catchWrapError);

export const getMetaPrivate = <T>(): Promise<JsonApiResponse<T>> =>
	fetchGetAuth<T>(`${API_HOST}/account/meta/private`)
		.catch(catchWrapError);

export const setMetaPrivate = <T>(body: T): Promise<JsonApiResponse<T>> =>
	fetchPostAuth<T>(`${API_HOST}/account/meta/private`, JSON.stringify(body))
		.catch(catchWrapError);

export const getMetaPublic = <T>(id: string): Promise<JsonApiResponse<T>> =>
	fetchGetAuth<T>(`${API_HOST}/account/meta/public/${id}`)
		.catch(catchWrapError);

export const setMetaPublic = <T>(body: T): Promise<JsonApiResponse<T>> =>
	fetchPostAuth<T>(`${API_HOST}/account/meta/public`, JSON.stringify(body))
		.catch(catchWrapError);
